import { Quill } from 'react-quill';

const Link = Quill.import('formats/link');

class StructuredLink extends Link {
  static create(value) {
    let valueParam = value;
    const node = super.create(valueParam);
    valueParam = this.sanitize(valueParam);
    node.setAttribute('href', valueParam);
    StructuredLink.updateRelAttribute(node, valueParam);
    return node;
  }

  static formats(domNode) {
    return domNode.getAttribute('href');
  }

  static updateRelAttribute(node, value) {
    const isInternalLink = value?.startsWith('https://lonca.co') || value?.startsWith('lonca.co');

    if (isInternalLink) {
      node.removeAttribute('rel');
    } else {
      node.setAttribute('rel', 'noopener noreferrer nofollow');
    }
  }

  format(name, value) {
    if (name === 'href' && value) {
      this.domNode.setAttribute('href', value);
      StructuredLink.updateRelAttribute(this.domNode, value);
    } else {
      super.format(name, value);
    }
  }
}

export default StructuredLink;

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, notification } from 'antd';
import { fetchSeoContents, createSeoContent } from '../../redux/seoContentSlice';
import SeoContentEditor from '../../components/SeoContentEditor/SeoContentEditor';
import './CreateSeoContent.scss';
import { ALL_LANGUAGES } from '../../constants';

function CreateSeoContent() {
  const { t } = useTranslation('translation');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [texts, setTexts] = useState(() => {
    const storedText = JSON.parse(window.localStorage.getItem('newSeoContentFields'))?.texts || {};
    ALL_LANGUAGES.forEach(lang => {
      if (!storedText[lang]) storedText[lang] = '';
    });
    return storedText;
  });
  const [title, setTitle] = useState(JSON.parse(window.localStorage.getItem('newSeoContentFields'))?.title);
  const [link, setLink] = useState(JSON.parse(window.localStorage.getItem('newSeoContentFields'))?.link);
  const [language, setLanguage] = useState("en");
  const [loading, setLoading] = useState(false);
  const [saved, setSaved] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);

    const payload = {
      title,
      texts,
      link,
      language,
    };

    createSeoContent(payload)
      .then(() => {
        setLoading(false);
        notification.success({
          message: t('errors.seo_content_save_success'),
        });
        window.localStorage.removeItem('newSeoContentFields');
        dispatch(fetchSeoContents({}));
        navigate('/seo-contents');
      })
      .catch(() => {
        setLoading(false);
        notification.error({
          message: t('errors.seo_content_save_error'),
        });
      });
  };

  return (
    <div className="create-seo-content-container">
      <div className="save-button">
        <Button type="primary" onClick={handleSubmit} loading={loading}>{t('buttons.save')}</Button>
      </div>
      <div className="editor-container">
        <div>
          <SeoContentEditor
            texts={texts}
            setTexts={setTexts}
            title={title}
            setTitle={setTitle}
            type="create"
            link={link}
            setLink={setLink}
            setSaved={setSaved}
          />
        </div>
      </div>
    </div>
  );
}

export default CreateSeoContent;

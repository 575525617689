import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, notification, Modal, Form, Select, Tooltip } from 'antd';

import { fetchSeoContents, fetchSeoContentById, updateSeoContent, publishSeoContent, deleteSeoContent, fetchTranslateSeoContent } from '../../redux/seoContentSlice';

import SeoContentEditor from '../../components/SeoContentEditor/SeoContentEditor';
import { ALL_LANGUAGES } from '../../constants';
import './EditSeoContent.scss';

function EditSeoContent() {
  const { t } = useTranslation('translation');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const id = useParams()?.id;

  const { user } = useSelector((state) => state.user);

  const [texts, setTexts] = useState({});
  const [title, setTitle] = useState("");
  const [titles, setTitles] = useState({});
  const [metaTitles, setMetaTitles] = useState({});
  const [loading, setLoading] = useState(false);
  const [publishLoading, setPublishLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [seoContent, setSeoContent] = useState({});
  const [initialSeoContent, setInitialSeoContent] = useState();
  const [link, setLink] = useState('');
  const [refresh, setRefresh] = useState(false);
  const [deleteWarningModal, setDeleteWarningModal] = useState(false);
  const [translateModel, setTranslateModel] = useState(false);
  const [sourceLanguage, setSourceLanguage] = useState("en"); 
  const [targetLanguages, setTargetLanguages] = useState([]); 
  const [form] = Form.useForm();
  const [translateloading, setTranslateLoading] = useState(false);
  const [saved, setSaved] = useState(false);

  const createSubmitPayload = async () => {

    const update = { };

    if (title !== initialSeoContent?.title) update.title = title;
    if (texts !== initialSeoContent?.texts) update.texts = texts;
    if (titles !== initialSeoContent?.titles) update.titles = titles;
    if (metaTitles !== initialSeoContent?.metaTitles) update.metaTitles = metaTitles;
    if (link !== initialSeoContent?.link) update.link = link;

    return update;
  };

  useEffect(() => {
    fetchSeoContentById(id)
      .then((res) => {
        setSeoContent(res?.data);
        setTexts(res?.data?.texts);
        setInitialSeoContent(res?.data);
        setTitle(res?.data?.title);
        setLink(res?.data?.link);
        setTitles(res?.data?.titles);
        setMetaTitles(res?.data?.metaTitles);
        setSaved(false)
      })
      .catch((err) => console.error(err));
  }, [id, refresh, user?._id]);

  const handleCancel = () => {
    navigate('/seo-contents');
  };

  const handleSubmit = async () => {
    setLoading(true);

    const update = await createSubmitPayload();

    updateSeoContent(id, update)
      .then(() => {
        setLoading(false);
        notification.success({
          message: t('errors.seo_content_save_success'),
        });
        dispatch(fetchSeoContents({ }));
        setRefresh((r) => !r);
      })
      .catch(() => {
        setLoading(false);
        notification.error({
          message: t('errors.seo_content_save_error'),
        });
        setRefresh((r) => !r);
      });
  };

  const handlePublish = () => {
    setPublishLoading(true);

    publishSeoContent(id)
      .then(() => {
        setPublishLoading(false);
        notification.success({
          message: t('errors.seo_content_save_success'),
        });
        dispatch(fetchSeoContents({ }));
        setRefresh((r) => !r);
      })
      .catch(() => {
        setPublishLoading(false);
        notification.error({
          message: t('errors.seo_content_save_error'),
        });
        setRefresh((r) => !r);
      });
  };

  const handleDelete = () => {
    setDeleteLoading(true);

    deleteSeoContent(id)
      .then(() => {
        setDeleteLoading(false);
        notification.success({
          message: t('errors.seo_content_delete_success'),
        });
        dispatch(fetchSeoContents({ }));
        navigate('/seo-contents');
      })
      .catch(() => {
        setDeleteLoading(false);
        notification.error({
          message: t('errors.seo_content_delete_error'),
        });
      });
  };

  const handleTranslate = () => {
    setTranslateLoading(true);
    form.validateFields()
      .then(() => {
        
        if(!texts[sourceLanguage] || texts[sourceLanguage] === "<p><br></p>"){
          notification.error({
            message: t('errors.source_text_required'),
          });

          setTranslateLoading(false);
          setTranslateModel(false);

          return;
        }
        
        fetchTranslateSeoContent({ id, sourceLanguage, targetLanguages })
          .then(() => {
            notification.success({
              message: t('errors.seo_content_save_success'),
            });

            dispatch(fetchSeoContents({ }));
            setRefresh((r) => !r);

            setTranslateLoading(false);
            setTranslateModel(false);
          })
          .catch(() => {
            setTranslateLoading(false);
            setTranslateModel(false);
            notification.error({
              message: t('errors.seo_content_save_error'),
            });
            setRefresh((r) => !r);
          });

        
      })
      .catch(() => {
        setTranslateLoading(false);
      });

  };

  return (
    <div className="create-seo-content-container">
      <Modal
        title={t('editor.delete_warning_title_seo_content')}
        visible={deleteWarningModal}
        onCancel={() => setDeleteWarningModal(false)}
        footer={[
          <>
            <Button onClick={() => {
              setDeleteWarningModal(false);
              handleDelete();
            }}
            >
              {t('buttons.delete')}
            </Button>
            <Button onClick={() => setDeleteWarningModal(false)}>{t('buttons.cancel')}</Button>
          </>,
        ]}
      >
        <p>{t('editor.delete_warning_seo_content')}</p>
      </Modal>
      <Modal
        title={t('editor.translate')}
        visible={translateModel}
        onCancel={() => setTranslateModel(false)}
        footer={[
          <>
            <Button onClick={() => {
              handleTranslate();
            }}
            >
              {t('buttons.translate')}
            </Button>
            <Button onClick={() => setTranslateModel(false)}>{t('buttons.cancel')}</Button>
          </>,
        ]}
      >
        <Form 
          form={form} 
          initialValues={{
            targetLanguages : targetLanguages,
            sourceLanguage: sourceLanguage
          }} 
          layout="vertical"
        >
          <Form.Item
            name="sourceLanguage"
            label={t('editor.source_language')}
            rules={[{ required: true, message: t('errors.source_language_required') }]}
          >
            <Select 
              onChange={(value) => {
                setSourceLanguage(value);
              }}
            >
              {ALL_LANGUAGES.map(lang => (
                <Option key={lang} value={lang}>
                  {lang.toUpperCase()}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="targetLanguages"
            label={t('editor.target_languages')}
            rules={[{ required: true, message: t('errors.target_languages_required') }]}
          >
            <Select
              mode="multiple"
              onChange={(value) => {
                  setTargetLanguages(value); 
              }}
            >
              {ALL_LANGUAGES?.filter(l => l !== sourceLanguage).map(lang => (
                <Option key={lang} value={lang} disabled={targetLanguages.length === 3 && !targetLanguages.includes(lang)}>
                  {lang?.toUpperCase()}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
        
      </Modal>
      <div className="edit-save-button">
        <div className="left">
          <Button type="secondary" onClick={handleCancel}>{t('buttons.cancel')}</Button>
        </div>
        <div className="right">
          <Button type="secondary" onClick={handleSubmit} loading={loading}>{t('buttons.save')}</Button>
          { (user?.role === 'BloggerAdmin' || user?.role === 'SeoContentEditor') ? (
            <>
              <Tooltip title={saved ? t('errors.saved_first') : ""} placement="top">
                <Button
                  type="secondary"
                  onClick={() => setTranslateModel(true)}
                  loading={translateloading}
                  disabled={saved}
                  className={saved ? "custom-disabled-button" : ""}
                >
                  {t('buttons.translate')}
                </Button>
              </Tooltip>
              <Button loading={publishLoading} type="secondary" onClick={handlePublish}>{ seoContent?.published ? t('buttons.unpublish') : t('buttons.publish')}</Button>
              <Button loading={deleteLoading} className="delete-button" type="secondary" onClick={() => setDeleteWarningModal(true)}>{t('buttons.delete')}</Button>
            </>
          )
            : null}
        </div>
      </div>
      <div className="editor-container">
        <div>
          <SeoContentEditor
            texts={texts}
            setTexts={setTexts}
            title={title}
            setTitle={setTitle}
            titles={titles}
            setTitles={setTitles}
            metaTitles={metaTitles}
            setMetaTitles={setMetaTitles}
            type="edit"
            link={link}
            setLink={setLink}
            setSaved={setSaved}
          />
        </div>
      </div>
    </div>
  );
}

export default EditSeoContent;
